
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import {
    ActionOn,
    DataGridAction,
    toDisplayColumns,
    useDataGridModel,
    useDeleteModalAction,
} from "@/core-ui/data-grid/compositions";
import ModelDetailsPanel from "../components/jobs/JobDetailsPanel.vue";
import ButtonSelect from "@/core-ui/forms/components/ButtonSelect.vue";
import Page from "@/core-ui/Page.vue";
import { createModelProps, meta, ModalType as Item } from "@/models/jobs.model";
import { useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import { computed, defineComponent, reactive, ref, watch, nextTick } from "vue";
import { useRouter } from "vue-router";
import clusterJobService from "@/cluster-ui/services/job.service";
import DeleteModalAction from "@/core-ui/data-grid/components/DeleteModal/DeleteModalAction.vue";
import { Job } from "@/types/job";
import { LabelType } from "@/core-ui/StatusLabel.vue";
import projectsService from "@/services/projects-service";
import _ from "lodash";
import { storageService } from "@/core-ui/data-grid/services";
import { activeClusterConnectionInfoStore } from "@/stores/activeClusterConnectionInfoStore";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import { JOBS_TABLE_FILTER } from "@/cluster-ui/helpers/const";
import authStore from "@/stores/authStore";
import clustersStore from "@/stores/clusters-store";
import { clusterApi } from "@/cluster-ui/services/apiClient";

const workloadKind = {
    runaiJob: "RunaiJob",
    mpiJob: "MPIJob",
    tfJob: "TFJob",
    pyTorchJob: "PyTorchJob",
    xgboostJob: "XGBoostJob",
    devWorkspaces: "DevWorkspace",
    rayJob: "RayCluster",
    kubeflowNotebook: "Notebook",
};

const workloadKindCannotBeCloned = {
    mpiJob: "MPIJob",
    tfJob: "TFJob",
    pyTorchJob: "PyTorchJob",
    xgboostJob: "XGBoostJob",
    kubeflowNotebook: "Notebook",
};

interface ClusterConnectStatus {
    activeClusterDomain: null | string;
    connected: boolean;
    errorCode: undefined | number;
}

export default defineComponent({
    components: {
        Page,
        ButtonSelect,
        DataGrid,
        ModelDetailsPanel,
        DeleteModalAction,
    },
    setup() {
        const HttpStatusCompound = 207;
        const props = useClusterUUIDKey({ key: "nodes" });
        const initialSearchQuery = ref<string | undefined>(undefined);
        const filterUserJobs = computed<boolean>(
            () =>
                authStore.isOnlyResearcherOrResearcherManager ||
                authStore.isOnlyResearcherOrResearcherManagerOrMlEngineer,
        );
        if (filterUserJobs.value) {
            initialSearchQuery.value = localStorage.getItem(JOBS_TABLE_FILTER) || "";
        }

        const selectedMode = computed<string>({
            get: () => {
                const v = storageService.getStr("jobs_view", "Current");
                if (v == "History") {
                    router.push("/jobs/history");
                }
                return v;
            },
            set: (v) => storageService.setStr("jobs_view", v),
        });
        const isHistory = computed<boolean>(() => selectedMode.value === "History");
        const model = useDataGridModel(
            createModelProps(props, "page", {
                get mode() {
                    return selectedMode.value;
                },
            }),
        );
        const router = useRouter();
        const state = reactive({
            projectsList: null,
        });
        const isAdmin = authStore.userInfo.roles.includes("admin");

        async function fetchProjectsList() {
            state.projectsList = await projectsService.getDesiredQueues();
        }

        fetchProjectsList();

        const clusterConnectStatus = reactive<ClusterConnectStatus>({
            activeClusterDomain: null,
            connected: true,
            errorCode: undefined,
        });

        function updateClusterConnectStatus(connected: boolean, errorCode: number | undefined): void {
            clusterConnectStatus.connected = connected;
            clusterConnectStatus.errorCode = errorCode;
        }

        watch(
            () => clustersStore.activeClusterUUID,
            async () => {
                clusterConnectStatus.activeClusterDomain = clustersStore.activeCluster?.domain || "";
                if (!clusterConnectStatus.activeClusterDomain) {
                    updateClusterConnectStatus(false, undefined);
                    return;
                }

                nextTick(async () => {
                    try {
                        const { status: statusCode }: { status: number } = await clusterApi.get("/api/v1/whoami");
                        if (statusCode === 200) {
                            updateClusterConnectStatus(true, undefined);
                        } else {
                            console.warn("The cluster response had an error with the status code: ", statusCode);
                            updateClusterConnectStatus(false, statusCode);        
                        }
                    } catch (error: any) {
                        console.warn("Could not connect to the cluster. Error: ", error);
                        updateClusterConnectStatus(false, error.response?.status);
                    }
                });
            },
            { immediate: true },
        );

        const actionDisabledMsgs = {
            uiV2CannotClone: () => `This operation is not applicable for workloads that were created in the new interface.`,
            distributedKindClone: () => `This operation is not applicable for distributed workloads.`,
            workloadKindProblem: () => `This operation is applicable only for Run:ai originated workloads.`,
            noProjectProblem: () =>
                isAdmin
                    ? "To submit a new job, First create at least one project"
                    : "To create a job, you need to be assigned to a project. Contact your administrator to be assigned. ",
            unauthorizedProblem: () => "The API server is not configured correctly. Contact your administrator", // when error code is 401
            connectivityProblem: (errorCode?: number) =>
                errorCode  
                    ? `There are issues with your connection to the cluster. Make sure you're using your organization's VPN, or contact your administrator (error code: ${errorCode})` // when error code is 403, 404 or 50X
                    : 'There are issues with your connection to the cluster. Contact your administrator.',
            missingDomain: () => "The cluster domain is not defined. Contact your administrator.",
        };

        function getConnectivityMessage(errorCode?: number): string {
            switch (errorCode) {
                case 401:
                    return actionDisabledMsgs.unauthorizedProblem();
                default:
                    return actionDisabledMsgs.connectivityProblem(errorCode);

            }
        }

        function handleJobAction(d: any, actionName: string) {
            //
            //   handle compound error. This is temporary placeholder, until multiple-job request
            //   is supported. For now, if we get compound and the status of the first (and only)
            //   job indicate action failed, raise exception so an error message will be displayed.
            //
            if (d.status == HttpStatusCompound && d.data.data?.length) {
                if (d.data.data.some((job: any) => !job.ok)) {
                    const forbiddenData = d.data.data.find(
                        (job: any) => job.error && (job.error.status === 403 || job.error.status === 503),
                    );
                    if (forbiddenData) {
                        throw `${forbiddenData.error.details}`;
                    }
                    throw `${actionName} failed`;
                }
            } else {
                setTimeout(() => model.refresh(), 4000);
            }
        }
        const mapToClusterJobId = ({ project, jobName }: Job) => ({ project, name: jobName } as any);
        const deleteModal = useDeleteModalAction<Item>({
            modelMeta: meta,
            delete: (jobs: Item[]) =>
                clusterJobService.delete(jobs.map(mapToClusterJobId)).then((d) => {
                    handleJobAction(d, "Deletion");
                    return d;
                }) as any,
        });

        const isResearcherAndNotProduction = computed(() => authStore.isResearcher);
        (window as any).ss = ref(false);
        const isNotRunaiJob = (job: Job) => !Object.values(workloadKind).includes(job.workloadKind);
        const isJobFromUiV2 = (job: Job) => job && job.workloadId != null;
        const cannotBeCloned = (job: Job) => Object.values(workloadKindCannotBeCloned).includes(job.workloadKind);
        const jobsThatCannotBeSuspended = (job: Job) =>
            ![
                workloadKind.mpiJob,
                workloadKind.tfJob,
                workloadKind.pyTorchJob,
                workloadKind.xgboostJob,
                workloadKind.rayJob,
                workloadKind.devWorkspaces,
                workloadKind.kubeflowNotebook,
            ].includes(job.workloadKind);

        const TrainingJob = "Train"; // the db and cluster jobs are differance ("Train", "Training")
        return {
            state,
            model,
            deleteModal,
            selectedMode,
            isHistory,
            initialSearchQuery,
            get displayColumns() {
                return toDisplayColumns(null, model.columns);
            },
            actions: [
                {
                    on: ActionOn.Item,
                    key: "delete",
                    icon: "raicon-remove",
                    label: `Delete Job`,
                    aid: "delete-job",
                    filter: (j: Item) => j.existsInCluster && isResearcherAndNotProduction.value,
                    disabled: (item) =>
                        (!clusterConnectStatus.activeClusterDomain && actionDisabledMsgs.missingDomain()) ||
                        (!clusterConnectStatus.connected && getConnectivityMessage(clusterConnectStatus.errorCode)) ||
                        (isNotRunaiJob(item) && actionDisabledMsgs.workloadKindProblem()),
                    permitted: true,
                    func: deleteModal.handle,
                },
                {
                    on: ActionOn.Item,
                    key: "connect",
                    icon: "raicon-play",
                    label: `Connect`,
                    aid: "connect-job",
                    permitted: true,
                    disabled: () =>
                        (!clusterConnectStatus.activeClusterDomain && actionDisabledMsgs.missingDomain()),
                    filter: (job: Job) =>
                        job.existsInCluster &&
                        isResearcherAndNotProduction.value &&
                        !!job.jobUrl &&
                        job.status === "Running",
                    func: (job: Job) => {
                        if (job.isJupyter || job.isTensorboard) {
                            window.open(job.jobUrl, "_blank");
                        } else {
                            const pattern = /^((http|https):\/\/)/;
                            let jobUrl = job.jobUrl;
                            if (job.jobUrl && job.jobUrl.includes(",")) {
                                jobUrl = job.jobUrl.split(", ")[0];
                            }
                            if (!pattern.test(jobUrl)) {
                                jobUrl = "http://" + jobUrl;
                            }
                            window.open(jobUrl, "_blank");
                        }
                    },
                },
                {
                    on: ActionOn.Item,
                    key: "suspend",
                    icon: "raicon-pause",
                    label: `Suspend`,
                    aid: "suspend-job",
                    permitted: true,
                    disabled: (item) =>
                        (!clusterConnectStatus.activeClusterDomain && actionDisabledMsgs.missingDomain()) ||
                        (!clusterConnectStatus.connected && getConnectivityMessage(clusterConnectStatus.errorCode)),
                    filter: (job: Job) =>
                        jobsThatCannotBeSuspended(job) &&
                        job.existsInCluster &&
                        isResearcherAndNotProduction.value &&
                        job.jobType === TrainingJob &&
                        job.status != LabelType.SUSPENDED &&
                        job.status != LabelType.DELETED &&
                        job.status != LabelType.ERROR &&
                        job.status != LabelType.SUCCESS,
                    func: (job: Job) =>
                        clusterJobService.suspend([mapToClusterJobId(job)]).then((d) => {
                            handleJobAction(d, "Suspend");
                            return {
                                type: "success",
                                msg: `Job ${job.name || job.job_name} suspended successfully`,
                            };
                        }),
                },
                {
                    on: ActionOn.Item,
                    key: "resume",
                    icon: "raicon-play",
                    label: `Resume`,
                    permitted: true,
                    aid: "resume-job",
                    disabled: () =>
                        (!clusterConnectStatus.activeClusterDomain && actionDisabledMsgs.missingDomain()) ||
                        (!clusterConnectStatus.connected && getConnectivityMessage(clusterConnectStatus.errorCode)),
                    filter: (job: Job) =>
                        job.existsInCluster &&
                        isResearcherAndNotProduction.value &&
                        job.jobType === TrainingJob &&
                        job.status == LabelType.SUSPENDED,
                    func: (job: any) =>
                        clusterJobService.resume([mapToClusterJobId(job)]).then((d) => {
                            handleJobAction(d, "Resume");
                            return {
                                type: "success",
                                msg: `Job ${job.name || job.jobName} resumed successfully`,
                            };
                        }),
                },
                {
                    on: ActionOn.None,
                    key: "add",
                    label: "+ New Job",
                    aid: "add-job",
                    disabled: () =>
                    (!clusterConnectStatus.activeClusterDomain && actionDisabledMsgs.missingDomain()) ||
                        (!clusterConnectStatus.connected && getConnectivityMessage(clusterConnectStatus.errorCode)) ||
                        (_.isEmpty(state.projectsList) && actionDisabledMsgs.noProjectProblem()),
                    filter: () => authStore.isResearcher,
                    permitted: true,
                    func: () => router.push("/jobs/submit") as any,
                },
                {
                    on: ActionOn.Item,
                    icon: "raicon-copy",
                    permitted: true,
                    key: "clone",
                    label: "Clone Job",
                    aid: "clone-job",
                    disabled: (item) =>
                        (isJobFromUiV2(item) && actionDisabledMsgs.uiV2CannotClone()) ||
                        (!clusterConnectStatus.activeClusterDomain && actionDisabledMsgs.missingDomain()) ||
                        (!clusterConnectStatus.connected && getConnectivityMessage(clusterConnectStatus.errorCode)) ||
                        (cannotBeCloned(item) && actionDisabledMsgs.distributedKindClone()) ||
                        (isNotRunaiJob(item) && actionDisabledMsgs.workloadKindProblem()),
                    filter: (job: Job) =>
                        job.existsInCluster && isResearcherAndNotProduction.value && job.parentWorkloadName,
                    func: (job: any) =>
                        router.push(
                            `/jobs/submit?JobName=${job.parentWorkloadName}&JobProject=${job.project}&JobType=${job.jobType}`,
                        ) as any,
                },
            ] as DataGridAction[],
        };
    },
});
